import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link as GatsbyLink } from "gatsby";
// Components
import { Box, Button, Container, Flex, Heading, Link, Box as Section, Text } from "@chakra-ui/react";
// import { Container } from "../components/Container";
// import { Section } from "../components/Section";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { Sidenote } from "../components/Sidenote";
import { FadeIn } from "../components/FadeIn";
import Img from "gatsby-image";
import styled from "styled-components";

const shortcodes = { Sidenote, FadeIn };

export default function Template({
  data,
  pageContext, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { mdx: post } = data; // data.mdx holds your post data
  const { next, previous } = pageContext;

  return (
    <MDXProvider components={shortcodes}>
      <Helmet title={`My Posts - ${post.frontmatter.title}`} />

      <Section
        position="relative"
        mt="50px"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundImage="linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))"
        py={[4, null, null, 12]}
        px={[null, null, null, 4]}
      >
        <HeroContainer p={6}>
          <Heading
            color="white"
            textTransform="capitalize"
            letterSpacing="0.08em"
            fontWeight="bold"
            fontSize={["2xl", "4xl"]}
            alignSelf={["start", "center"]}
          >
            {post.frontmatter.title}
          </Heading>
          <Heading
            position="relative"
            color="white"
            textTransform="uppercase"
            letterSpacing="0.2em"
            fontWeight="medium"
            mt={8}
            mb={0}
            fontSize={["sm", "md"]}
            alignSelf={["start", "center"]}
            _before={{
              content: "''",
              position: "absolute",
              height: "5px",
              backgroundImage: "linear-gradient(to right, var(--color-primary), var(--color-primary-light), var(--color-primary))",
              width: "100%",
              maxWidth: { base: "50px", sm: "350px" },
              top: "-1rem",
              borderRadius: "3px",
              left: { base: "0", sm: "50%" },
              transform: { sm: "translateX(-50%)" },
            }}
          >
            {post.frontmatter.description}
          </Heading>
          <Box
            color="white"
            mt={2}
            fontSize={["xs", "sm"]}
            fontWeight="thin"
            alignSelf={["start", "center"]}
            textShadow="none"
            letterSpacing="0.1em"
          >
            {post.frontmatter.date}
            <Tags>
              {post.frontmatter.tags.map((tag, i) => (
                <Tag key={i} to={`/tags/${tag}`}>
                  <div>
                    <svg
                      style={{
                        width: "1rem",
                        verticalAlign: "middle",
                        marginRight: "0.4em",
                        display: "inline-block",
                      }}
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="tags"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      className="svg-inline--fa fa-tags fa-w-20"
                    >
                      <path
                        fill="var(--color-gray-400"
                        d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
                      />
                    </svg>
                    <Text as="span" color="brand.lightShades" _hover={{ color: "brand.lightAccent" }}>
                      {tag}
                    </Text>
                  </div>
                </Tag>
              ))}
            </Tags>
          </Box>
          <HeroImage fluid={post.frontmatter.heroImage.childImageSharp.fluid} />
        </HeroContainer>
      </Section>
      <Section position="relative" overflow="visible">
        <StyledContainer>
          <Box className="blog">
            <MDXRenderer>{post.body}</MDXRenderer>
          </Box>
        </StyledContainer>
      </Section>
      <Section pb={0}>
        <Container p={0} maxWidth="container.md" my={[0, 3]}>
          <Flex flexDirection={["column", "row"]} justifyContent="none" gridGap={[0, 0]}>
            {previous && (
              <Button
                display="block"
                w="full"
                background="brand.lightShades"
                border="1px solid"
                borderColor="gray.300"
                color="brand.main"
                fontWeight="medium"
                _hover={{ background: "brand.lightAccent", color: "brand.lightShades" }}
                _active={{ background: "brand.lightAccent", color: "brand.lightShades" }}
                borderRadius="none"
                height="auto"
                py={4}
              >
                <GatsbyLink to={previous.frontmatter.path}>
                  <Text textAlign={["center", "left"]} lineHeight={1.3} fontWeight="bold" textTransform="uppercase">
                    Previous Post
                  </Text>
                  <Text textAlign={["center", "left"]} lineHeight={1.3} mt={0} fontSize="sm" fontStyle="italic">
                    {previous.frontmatter.title}
                  </Text>
                </GatsbyLink>
              </Button>
            )}
            {next && (
              <Button
                display="block"
                w="full"
                background="brand.lightShades"
                border="1px solid"
                borderColor="gray.300"
                color="brand.main"
                fontWeight="medium"
                _hover={{ background: "brand.lightAccent", color: "brand.lightShades" }}
                _active={{ background: "brand.lightAccent", color: "brand.lightShades" }}
                borderRadius="none"
                height="auto"
                py={4}
              >
                <GatsbyLink to={next.frontmatter.path}>
                  <Text textAlign={["center", "left"]} lineHeight={1.3} fontWeight="bold" textTransform="uppercase">
                    Next Post
                  </Text>
                  <Text textAlign={["center", "left"]} lineHeight={1.3} mt={0} fontSize="sm" fontStyle="italic">
                    {next.frontmatter.title}
                  </Text>
                </GatsbyLink>
              </Button>
            )}
          </Flex>
        </Container>
      </Section>
    </MDXProvider>
  );
}

// Styled Components
const StyledHeroSection = styled(Section)`
  &&& {
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media ${({ theme }) => theme.mq.sm} {
      padding-top: 5rem;
      padding-bottom: 5rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
`;
const StyledSection = styled(Section)`
  &&& {
    padding: 0;

    @media ${({ theme }) => theme.mq.sm} {
      padding-top: 2rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 5rem;
    }
  }
`;
const HeroContainer = styled(Container)`
  max-width: var(--screen-lg);
  color: var(--color-white);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 2px var(--color-black);
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.mq.sm} {
    font-size: var(--font-size-4xl);
    min-height: 250px;
  }
`;
const HeroTitle = styled.h2`
  color: var(--color-white);
  text-transform: capitalize;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-3xl);
  margin-bottom: 0;
  align-self: start;

  @media ${({ theme }) => theme.mq.sm} {
    align-self: center;
    font-size: var(--font-size-4xl);
  }
`;
const HeroDescription = styled.h3`
  position: relative;
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  margin-top: 2.4rem;
  margin-bottom: 0;
  font-size: var(--font-size-base);
  line-height: 1.5;
  align-self: start;

  @media ${({ theme }) => theme.mq.sm} {
    align-self: center;
    font-size: var(--font-size-lg);
  }

  &:before {
    content: "";
    position: absolute;
    height: 5px;
    background-image: linear-gradient(to right, var(--color-primary), var(--color-primary-light), var(--color-primary));
    width: 100%;
    max-width: 50px;
    top: -1.6rem;
    border-radius: 3px;
    left: 0;

    @media ${({ theme }) => theme.mq.sm} {
      max-width: 350px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
const StyledContainer = styled.div`
  max-width: var(--screen-md);
  margin: 0 auto;
  margin-top: 0rem;
  background: var(--color-white);
  box-shadow: none;
  padding: 1.6rem;
  @media ${({ theme }) => theme.mq.sm} {
    margin-top: -6rem;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px 6px;
  }
`;
const HeroMeta = styled.div`
  color: var(--color-white);
  line-height: 0;
  margin-top: 2rem;
  text-shadow: none;
  font-size: 0.4rem;
  font-weight: var(--font-weight-medium);
  align-self: start;

  @media ${({ theme }) => theme.mq.sm} {
    align-self: center;
    font-size: 0.8rem;
  }
`;
const Date = styled.p`
  display: inline;
  margin-top: 0rem;
`;
const Tags = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 1rem;
`;
const Tag = styled(GatsbyLink)`
  display: inline-block;
  text-decoration: none;
  color: var(--color-white);
  transition: color 200ms ease-out;

  border-radius: 2px;
  font-weight: var(--font-weight-medium);

  &:hover {
    color: var(--color-primary-light);
  }
`;
const HeroImage = styled(Img)<HeroImageProps>`
  position: absolute !important;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

// Types
type HeroImageProps = {
  fluid?: any;
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        status
        path
        heroImage {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`;
