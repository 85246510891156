import React from "react";
import styled from "styled-components";
// Components

const Sidenote: React.FC<SidenoteProps> = ({ color = "var(--color-text)", background = "var(--color-gray-100)", title, content, type }) => {
  return (
    <BaseWrapper color={color} background={background}>
      <Title type={type}>{title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
    </BaseWrapper>
  );
};

// Styled Components
const BaseWrapper = styled.div<BaseWrapperProps>`
  &&& {
    padding: 0.4rem 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-left-width: 8px;
    border-left-style: solid;
    border-radius: 6px 6px 6px 6px;

    color: ${({ color }) => color};
    border-color: ${({ color }) => color};
    background: ${({ background }) => background};
  }
`;

const Title = styled.span<TitleProps>`
  display: block;
  font-size: 0.9rem;
  font-weight: var(--font-weight-bold);
  &:before {
    content: ${({ type }) => (type === "tip" ? "url(/icons/icon-tip.svg)" : null)};
    content: ${({ type }) => (type === "note" ? "url(/icons/icon-note.svg)" : null)};
    content: ${({ type }) => (type === "warning" ? "url(/icons/icon-warning.svg)" : null)};
    padding-right: 0.5rem;
    vertical-align: top;
  }
`;
const Content = styled.div`
  &&& {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 0.8rem;
    line-height: 1.6;
  }
`;

// Types
type SidenoteProps = {
  title: string;
  content: string;
  background?: string;
  color?: string;
  type?: string;
};
type BaseWrapperProps = {
  background?: string;
  color?: string;
};
type TitleProps = {
  type?: string;
};

export { Sidenote };
